import React from "react";
import brainAi from "../../assets/brainAi.png";
const Hero = () => {
  const examTiles = ["IELTS", "SOP Writer", "SAT"];
  const appInfoArray = [
    { id: 1, heading: "2000+", description: "5 Star Reviews" },
    { id: 2, heading: "10000+", description: "Registered Students" },
    { id: 3, heading: "50000+", description: "Hours Taught" },
    { id: 4, heading: "4.8", description: "App Rating" },
  ];
  return (
    <div>
      <div className="sm:flex sm:flex-row-reverse items-center text-[#0C0C0C] text-center sm:text-left  mt-14 md:mt-24 md:px-14 px-4 ">
        <div>
          <img src={brainAi} alt="AiImage" />
        </div>
        <div>
          <p className="lg:text-[64px] sm:text-[40px] text-3xl font-black leading-tight mb-4">
            Your Global Education Dream
          </p>
          <p className="lg:text-[32px] mb-11">
            Supercharged with{" "}
            <span className="bg-[#FCF3FF] text-[#A033FF] inline-block rounded-xl px-2 py-1.5">
              Artifical Intelligence
            </span>
          </p>
          <div>
            <p className="mb-6">
              Choose your Exam and start your journey now.{" "}
            </p>
            <div className="flex md:gap-6 gap-3 flex-wrap mb-10 md:mb-40">
              {examTiles.map((item) => {
                return (
                    <div className="px-4 py-2 rounded-lg border-2 border-purple-600 flex items-center justify-center mb-4 gap-1" key={item.id}>
                      <p className="text-purple-600 md:text-sm font-extrabold text-xs m-0 p-0">
                        {item}
                      </p>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-16 lg:mb-28 m-auto sm:flex sm:w-[85%] sm:justify-between">
        {appInfoArray.map((item) => {
          return (
              <div className="text-center mb-2.5 text-gray-800 font-extrabold" key={item.id}>
                <p className=" text-xl   lg:text-[40px] sm:mb-1">
                  {item.heading}{" "}
                  {item.id == 4 && (
                    <span className="lg:text-xl  text-[12px]">★★★★★</span>
                  )}
                </p>
                <p className="text-[10px] lg:text-base">{item.description} </p>
              </div>
          );
        })}
      </div>
    </div>
  );
};

export default Hero;
